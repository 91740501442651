/*******************************
         Site Overrides
*******************************/

.ui.inverted.table:not(.basic) {
  border-color: @grey !important;
  border-width: 1px;
  border-style: solid;
}

.ui.table td.negative {
  background: #db282863 !important;
  color: #ffffff !important;
}

.ui.table td.positive {
    background: #4a564a !important;
    color: #ffffff !important;
}

.ui.table thead th:has(span) {
  cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0.87) !important;
}

.ui.table thead th:has(span):hover {
   color: @primaryColor;
  border: 2px solid @primaryColor !important;
}

.ui.inverted.table.basic.striped {
  background-color: rgba(0,0,0,.1);
  border: 1px solid @grey;
  border-radius: 4px;

  thead tr, tbody tr:nth-child(2n) {
    background-color: rgba(0,0,0,.4) !important;
  }
}
.ui.table td.very-condensed {
  padding: 0.25rem !important;
}

.hephaestus {
  .ui.table.inverted.adjustable {  
      tr td {
        border-color: @brandBackgroundTint !important;
      }
    }
}