/*******************************
         Site Overrides
*******************************/

.ui.divider.vertical {
    position: static;
    height: 100%;

    &::before {
        top: 0;
        height: 100%;
    }
    &::after {
        bottom: unset;
        height: 100%;
    }
}