/*******************************
         Site Overrides
*******************************/

.ui.label.basic.purple {
    color: @purpleLight !important;
    border-color: @purpleLight !important;
}

.ui.label.basic.inverted {
    color: white !important;
    border-color: white !important;
}

/***** APPLICATION CATEGORY LABELS *****/
.ui.application-category {    
    display: flex !important;
    flex-wrap: wrap;
    
    a.ui.label {       
            display: flex; 
            align-items: center;
            width: fit-content;
            background-color: @purpleLight;
            border-radius: 24px;
            color: white;
            font-weight: normal;
            padding-block: .25rem; 
    }

     &.readonly {
        .ui.label {
            background-color: transparent;
            border: 1px solid white;
            border-radius: 24px;
            color: white;
            font-weight: normal;
            padding-block: .25rem;
        }
     }
}