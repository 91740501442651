/*******************************
        User Overrides
*******************************/

.ui.selection.dropdown.visible, .ui.selection.dropdown.active .high-z-index {
  z-index: 1550;
}

.ui.selection.dropdown {
  background: @greyDark;
  border-color: white;
  word-break: break-all;

  &.bg-primary {
    background: @primaryColor;
  }

  > input.search {
    color: white;
  }
}

/* Menu Item Hover */
.ui.dropdown .menu > .item.disabled:hover {
  background: unset;
  color: unset;
  z-index: unset;
}

.ui.dropdown .menu > .selected.item:hover {
  background: @hoveredItemBackground;
  color: @hoveredItemColor;
  z-index: @hoveredZIndex;
}

.ui.dropdown.error > .menu > .item {
  color: @itemColor;
}

.ui.dropdown .menu > .item.tooltip-wrapper {
    background: unset;
    color:#7f7f7f;
    :hover {
      pointer-events: none;
  }
}

/* Works on Firefox */
.dch-scrollbar {
  scrollbar-color: #83218e #979797 !important;
  scrollbar-width: thin !important;
}

.dch-scrollbar::-webkit-scrollbar-thumb {
  background: #ac4dbb;
  -webkit-border-radius: 1ex;
  border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px #252525;
  box-shadow: 0px 1px 2px #252525;

  &:hover {
    background: #83218e;
  }
}

.dch-scrollbar::-webkit-scrollbar-corner {
  background: #252525;
}

/* Works on Firefox */
.ui.dropdown .menu {
  &:extend(.dch-scrollbar);
}

/* Works on Chrome */
.ui.dropdown .menu::-webkit-scrollbar {
  &:extend(.dch-scrollbar::-webkit-scrollbar);
}

.ui.dropdown .menu::-webkit-scrollbar-thumb {
  &:extend(.dch-scrollbar::-webkit-scrollbar-thumb);
}

.ui.dropdown .menu::-webkit-scrollbar-corner {
  &:extend(.dch-scrollbar::-webkit-scrollbar-corner);
}


/*---------------------------------
        ReadOnly Inputs
-----------------------------------*/

.ui.dropdown.readonly {
  pointer-events: none;
  background: none;
  border: none;
  padding-inline: 0;
}

.ui.dropdown.readonly > i.dropdown.icon {
  display: none;
}

.ui.dropdown.readonly > a > i.delete.icon {
  display: none;
}

.ui.dropdown.readonly.italic > div.text {
  font-style: italic;
}

.ui.dropdown.readonly > div.ui.label {
  background: none;
  color: unset;
  padding: 0;
  margin: 0;
  display: block;

  > i.delete.icon {
    display: none;
  }

  > a {
    opacity: 1;
    color: @white;
    font-weight: normal;    

    &:hover {
       color: @purpleLight;
    }
  }
}


/*---------------------------------
        Compact Inputs
-----------------------------------*/

.ui.dropdown.selection.compact {
  min-height: fit-content;
  padding-block: .5rem;

  .dropdown.icon {
   top: .5em !important;
  }
}