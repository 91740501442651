/*******************************
         Site Overrides
*******************************/

.ui.checkbox.disabled .box:hover::before,
.ui.checkbox.disabled label:hover::before {
  border-color: @grey;
}

.ui.checkbox label::before, .ui.toggle label::before {
  border: 1px solid white !important;
}
.ui.checkbox label:hover, .ui.checkbox + label:hover {
  color: white;
}

.ui.checkbox.disabled + span {
  color: @disabledCheckboxLabelColor;
  cursor: default !important;
  opacity: @disabledCheckboxOpacity;
}