/*******************************
         Site Overrides
*******************************/

.ui.breadcrumb {
    .section {
        text-decoration: none;

        &.active {
            .name {
                color: white;
            }
            &:hover {
                text-decoration: none;
                cursor: default;
            }
        }

        &:not(.active):hover.name {
            cursor: pointer;
        }
    }
}