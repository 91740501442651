/*******************************
        User Overrides
*******************************/

.dimmable.fitted {
        padding-inline: 0;
        width: 100%;
        min-height: 5rem;
}

// Only change the parent dimmer container if it specific to config modal,
// Otherwise, resume default behaviour
// justification: cannot access/ add id to parent dimmer is it is hidden

.dimmed.dimmable > .ui.visible.dimmer:has(> #config-modal) {
        justify-content: flex-start;
}

// avoid blurring popup inside modals
.blurring.dimmed.dimmable > :not(.dimmer).popup {
  filter: none;
}

.ui.dimmer.inherit {
        background-color: inherit;
}