@import "semantic-ui/site/globals/site.variables";

//TODO = create globals file for colours and other common style variables
@dark-grey: #252525;

.hephaestus {
  table.ui.inverted.table {
    border-color: @brandBackgroundTint !important;
    background-color: transparent;

    th {
      background-color: @brandBackgroundTint;
      border-block: none !important;
      padding: 0.5rem 0.75rem;
      position: relative;

      &:not(:first-child) {
        border-left: 1px solid @dark-grey !important;
      }
    }

    tr td {
      border-block: transparent !important;
      padding: 0.5rem 0.75rem !important;

      &:not(:first-child) {
        border-left: 1px solid @brandBackgroundTint;
        border-color: @brandBackgroundTint !important;
      }
    }

    &.adjustable {
      th {
        padding-left: 1rem;

        &:not(:first-child):before {
          content: "\203A"; //❯
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 2px;
          color: @dark-grey;
        }

        &:not(:last-child):after {
          content: "\2039"; //❮
          position: absolute;
          right: 2px;
          top: 50%;
          transform: translateY(-50%);
          color: @dark-grey;
        }
      }

      tr td {
        padding: 0.5rem 0.75rem 0.5rem 1rem !important;
      }
    }
  }
}
