/*******************************
         Site Overrides
*******************************/

.ui.input.italic > input {
  font-style: italic;
}

.ui.input > input:hover {
  border-color: @focusBorderColor;
}

.ui.input ::placeholder {
  opacity: 0.4;
}

.ui.input ::selection {
  background: @highlightBackground;
}

input:checked label {
  color: white !important;
}

input:focus:checked label {
  color: white !important;
}

/*---------------------------------
        Inverted Inputs
-----------------------------------*/

.ui.input.inverted {
  color: @invertedInputColor;
}

.ui.input.inverted > input {
  background: @invertedBackground;
  border: @invertedBorder;
  color: @invertedInputColor;
}

.ui.input.error.inverted > input {
  background-color: @invertedBackground;
  border-color: @errorBorder;
  color: @errorColor;
  box-shadow: @errorBoxShadow;
}

/*---------------------------------
        ReadOnly Inputs
-----------------------------------*/

.ui.input.readonly {
  &.vertical {
    > input {
      padding-top: .25rem;
    }
  }
  > input {
    pointer-events: none;
    background: none;
    border: none;
    padding-inline: 0;
  }
}
