/*******************************
        User Overrides
*******************************/

.ui.attached.tabular.menu {
        
        border-color: @tabularBorderColor;
        
        .item {
                background-color: black;
                border-color: @tabularBorderColor;
                border-radius: @tabularBorderRadius @tabularBorderRadius 0px 0px;
                padding: .5rem 2rem;

                &.active {
                        color: white;    
                }

                &:not(.active) {
                        background-color: @grey;
                        color: @greyDark;
                        font-weight: bold;
                }

                &:not(:first-child){
                        margin-left: 1rem;
                }
        }
}

.attached.segment.active.tab {
        background-color: black;   
        border-color: @tabularBorderColor;
        border-top: transparent;
}
