.flex-cards {
  container-type: inline-size;
}

@container (min-width: 1601px) {
  .flex-width-card {
    width: 20cqw;
  }
}

@container (max-width: 1600px) {
  .flex-width-card {
    width: 25cqw;
  }
}

@container (max-width: 1200px) {
  .flex-width-card {
    width: 33.3cqw;
  }
}

@container (max-width: 900px) {
  .flex-width-card {
    width: 50cqw;
  }
}
