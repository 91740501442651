/*******************************
         Site Overrides
*******************************/

.ui.grid {
    .row.collapsed {
        padding: 0 !important;
    }
    
    .column.collapsed {
        padding: 0 !important;
    }
} 