/*******************************
         Site Overrides
*******************************/

.ui.modal {
  background: #000000;

  padding: 0.75rem;

  .header, .content, .actions {
    color: #ffffff;
    background: #000000;
  }

  /*--------------
      Header
  ---------------*/

  .header {
    font-family: @headerFontFamily;
    margin: @headerMargin;
    padding: @headerPadding;
    box-shadow: @headerBoxShadow;
    border-bottom: @headerBorder;
  }
  .header:not(.ui) {
    font-size: @headerFontSize;
    line-height: @headerLineHeight;
    font-weight: @headerFontWeight;
  }


  /*--------------
      Content
  ---------------*/

  .content {
    width: 100%;
    font-size: @contentFontSize;
    line-height: @contentLineHeight;
    padding: @contentPadding;
    padding-top: .5rem;


    /* Description */
    .description {
      flex: 1 0 auto;
      min-width: 0px;
      align-self: @descriptionVerticalAlign;
    }
  }

  .image.content {
    display: flex;
    flex-direction: row;
  }
  
  /*--------------
     Actions
  ---------------*/

  .actions {
    padding: @actionPadding;
    border-top: @actionBorder;
    text-align: @actionAlign;

    .button {
      margin-left: @buttonDistance;
    }
  }
}
