/*******************************
        Site Overrides
*******************************/

.ui.message.dismissable {
        padding: 1rem 2.5rem 1rem 1.5rem;
}

.ui.message.info {
        padding-block: .5rem;
        font-size: .9em;
}