/*******************************
         Site Overrides
*******************************/

.ui.form textarea {
    background: #000;
    color: #fff;
    font-family: monospace;
    resize: none;
    min-height: 30px;
}

.ui.form textarea:focus {
    background: #000;
    border-color: #fff;
    color: #fff;
}