.section {
  width: 30px;
  height: 30px;
  display: inline-block;
}

.left-brace-tl {
  border-right: 1px solid white;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  border-bottom-right-radius: 10px;
  margin-right:-2px;
  margin-top: 10px;
}

.left-brace-tr {
  margin-bottom: 10px;
  border-left: 2px solid white;
  -webkit-border-top-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  border-top-left-radius: 10px;
}

.left-brace-bl {
  margin-top: -4px;
  border-right: 2px solid white;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  border-top-right-radius: 10px;
  margin-right:-2px;
}

.left-brace-br {
  margin-bottom: -8px;
  border-left: 2px solid white;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-left-radius: 10px;
}

.right-brace-bl {
  border-right: 2px solid white;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  border-bottom-right-radius: 10px;
  margin-right:-2px;
  margin-top: 13px;
}

.right-brace-br {
  margin-bottom: 10px;
  border-left: 2px solid white;
  -webkit-border-top-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  border-top-left-radius: 10px;
}

.right-brace-tl {
  margin-top: -4px;
  border-right: 2px solid white;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  border-top-right-radius: 10px;
  margin-right:-2px;
}

.right-brace-tr {
  margin-bottom: -8px;
  border-left: 2px solid white;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-left-radius: 10px;
}
