/*******************************
        User Overrides
*******************************/

.ui.accordion.inverted {

        border-bottom: 1px solid @grey;

        div.title, div.content {
                border: 1px solid @grey;
                border-bottom: none;
        }

        &.styled {
                border: 1px solid @grey;
        }
}

.ui.accordion.inverted, .ui.accordion.inverted.styled {
        width: 100%;
        padding: 0;
        background: none;
                
        div.title, div.content {
                padding: .75rem 1rem;
                font-size: .9em;  

                &.no-indent {
                        padding: .75rem 0;
                }
                &.default-font-size {
                        font-size: 1rem; 
                }

        }

        div.content {
                border-top-color: fade(@grey, 30%);
        }

        .dimmer > div.content {
                border: unset !important;
        }

        &.striped {
                div.title:nth-child(4n+1) {
                        background-color: @greyDark;
                }

                div.content:nth-child(4n+2) {
                        background-color: @greyDark;
                }
        }

        div.title.selected {
                color: @purpleLight;
        }
}

.ui.accordion .ui.header {
        margin-top: 0;
}

&.borderless {
    border: 0 !important;
    div.title, div.content {
        border: 0 !important; 
    }
}