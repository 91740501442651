/*******************************
         Site Overrides
*******************************/

.ui.button {
    &.primary {
        background-image: @primaryGradientBackground;    
        background-size: auto 200%;
        transition: 0.5s;

        &:hover {
            background-position: center bottom; /* change the direction of the change here */
        }

        &:disabled {
            background-image: @primaryGradientBackground !important;
        }
    }

    &.secondary {
        background-image: @secondaryGradientBackground;
        border: 1px solid @grey;
        background-size: 200% auto;
        transition: 0.5s;

        &:hover {
            background-position: right center; /* change the direction of the change here */
        }

        &:active {}
    }

    &.icon {
        i.icon-right {
            padding-inline: .5rem;
        }
    }
}

.ui.buttons.basic.inverted {
    .button.active {
        box-shadow: @basicInvertedHoverBoxShadow !important;
    }
}

.ui.button.basic.inverted.toggle {
    border: 1px solid @grey;
    color: @grey !important;
    box-shadow: unset !important;

    &.active {
        border: 1px solid @white;        
        color: @white !important;
        background-color: transparent !important;
    }

    &:focus {
        box-shadow: unset !important;
    }
}
.ui.button.basic.inverted.mini.text-area-overlay {
    background-color: @greyDark !important;
}