@import "semantic-ui/site/globals/site.variables";

@import "./hephaestus.less";
@import "./container.css";
@import "./adjustableTable.less";

@purple: #84218e;
@light-purple: #dc73ff;
@dark-grey: #252525;
@dark-grey-tint: #3b3b3b;

@grey-text: #979797;

html,
body {
  height: 100%;
  background-color: black;
  color: white;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

@font-family:
  "Lato",
  "Helvetica Neue",
  Arial,
  Helvetica,
  sans-serif !important;
@font-family-header:
  "IBM Plex Sans",
  "Montserrat SemiBold",
  "Lato",
  "Helvetica Neue",
  Arial,
  Helvetica,
  sans-serif !important;
@font-size: 16px;

pre {
  white-space: pre-wrap;
  font-size: 0.9rem;
}

body {
  margin: 0;
  font-family: @font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: @font-family-header;
}

small {
  line-height: 0.8em;
}

.italic {
  font-style: italic;
}

.text-strong {
  color: #00b5ad;
}
.text-neutral {
  color: #979797;
}
.text-weak {
  color: #e03997;
}

main#content {
  padding-top: 2rem;
}

.field-success {
  color: #00735b;
}

.field-error {
  color: #e53e3e;
}

.date-field-error .ui.input input {
  border-color: #e53e3e;
  color: #e53e3e;
}

/* INPUT BUTTON */

input[type="file"] {
  width: 100%;

  &::file-selector-button {
    background-color: transparent;
    color: #f9fafb;
    padding: 0.5rem 0.875rem;
    border: 2px solid rgba(255, 255, 255, 50%);
    border-radius: 0.25rem;
    cursor: pointer;
    margin-right: 0.75rem;

    &:hover {
      border: 2px solid white;
    }
  }
}

.chart-stream-select {
  width: 33.3%;
  float: left;
  margin-right: 1em;
  margin-top: 1em;
}

.no-stream-data {
  text-align: center;
  width: 50%;
  padding-top: 5em;
}

.building-submit {
  margin-top: 4em;
}

/* New Data Source
/****************/
.new-ds-fields {
  display: flex;
  flex-wrap: wrap;
}

.new-ds-field {
  padding: 0 1rem;
  width: 100%;
  max-width: 25rem;
}

.new-ds-field label {
  font-size: larger;
  display: block;
  margin-bottom: 0.5rem;
}

.new-ds-field select {
  width: 100%;
}

.new-ds-field-description {
  margin-top: 0.3rem;
  font-size: smaller;
}

.new-ds-submit-error {
  color: red;
  margin-bottom: 1rem;
  border: 1px solid red;
  padding: 0.5rem;
  width: 51em;
}

div.new-ds-submit-error p {
  max-width: 50em;
}

@font-face {
  font-family: Montserrat;
  src: url(assets/fonts/Montserrat-Regular.woff) format("woff");
}

@font-face {
  font-family: Montserrat;
  src: url(assets/fonts/Montserrat-Regular.woff) format("woff");
}

@font-face {
  font-family: Montserrat Medium;
  font-weight: 500;
  src: url(assets/fonts/Montserrat-Medium.woff) format("woff");
}

@font-face {
  font-family: Montserrat Bold;
  font-weight: 700;
  src: url(assets/fonts/Montserrat-Bold.woff) format("woff");
}

@font-face {
  font-family: Montserrat SemiBold;
  font-weight: 700;
  src: url(assets/fonts/Montserrat-SemiBold.woff) format("woff");
}

@font-face {
  font-family: Montserrat ExtraBold;
  font-weight: 800;
  src: url(assets/fonts/Montserrat-ExtraBold.woff) format("woff");
}

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 70%;
  width: 100%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 0;
}

/* leaflet map */
.leaflet-div-icon {
  background: white;
  border: 1px solid #ac4dbb;
}

.leaflet-container {
  font: @font-size @font-family;
}

// Hide arrows from input number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.progress-bar {
  display: flex;
  background-image: linear-gradient(to right, #e6aafc 0%, #7f1f8d 100%);
  border-radius: 3px;
  height: 30px;
  width: 100%;

  .step-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @greyDark;
    color: @grey;

    &.done,
    &.current {
      background-color: transparent;
      color: black;
    }

    .step {
      flex: 1;
      text-align: center;
    }

    &.current .step {
      font-weight: bold;
    }

    &.current .angle {
      background-color: @dark-grey;
    }

    &:first-child {
      margin-left: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-child {
      margin-right: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .angle {
    height: 30px;
    width: 20px;
    background-color: transparent;
    position: relative;

    &::before,
    &::after {
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      left: -8px;
      background-color: black;
    }

    &::before {
      bottom: 0;
      transform: skew(-35deg);
    }
    &::after {
      top: 0;
      transform: skew(35deg);
    }
  }
}

//React-Datepicker

.react-datepicker-wrapper {
  width: 100%;
}

&.react-datepicker {
  min-width: 328px;

  //colour scheme
  color: white !important;
  background-color: black !important;

  .react-datepicker__day-name,
  .react-datepicker-time__header {
    color: white !important;
  }

  .react-datepicker__day {
    color: white !important;

    &.react-datepicker__day--keyboard-selected {
      border: 1px solid @purple;
      background: none !important;
    }

    &.react-datepicker__day--today {
      border: 1px dashed @light-purple;
      border-radius: 3px;
      background: none !important;
    }

    &:hover:not(.react-datepicker__day--disabled) {
      color: @dark-grey !important;
      background-color: @light-purple !important;
    }
  }

  .react-datepicker__day--outside-month,
  .react-datepicker__day--disabled {
    color: @grey-text !important;
  }

  .react-datepicker__header,
  .react-datepicker__time {
    background-color: black !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected {
    background-color: @purple !important;
  }

  .react-datepicker__time-list-item:hover {
    background-color: @light-purple !important;
  }

  //fonts
  .react-datepicker__day-name {
    font-weight: bold;
  }

  .react-datepicker__header {
    .ui.dropdown .menu {
      .item {
        font-size: 0.8rem;
        font-weight: normal;
      }
    }
  }

  //layout & spacing
  .react-datepicker__time-list {
    padding-top: 0.5rem;
  }
}

//DateTimeInput
.datetimeinput-full div.input {
  width: 100%;
}

.datetimeinput-full.error input {
  border-color: @negativeBorderColor;
  color: @negativeTextColor;
}

// highlighted text
.highlighted {
  background: #83218e;
  color: white;
}

//use this to fix the RHS model panel as sticky when scrolling down model tree
.sticky-container {
  display: flex;
  align-items: flex-start !important;
  .sticky-col {
    position: sticky !important;
    top: 0;
  }
}

// We have to have this to force configuration text areas to show when you've selected text
textarea::selection {
  background-color: rgba(100, 100, 100, 0.4);
  color: rgba(0, 0, 0, 0.87);
}

.rjsf label {
  display: none !important;
}

.rjsf .error-field input {
  border-color: @negativeBorderColor;
}

.rjsf .header {
  color: white;
}

.rjsf .ui.dividing.header {
  display: none;
}

.rjsf .sui-description {
  display: none;
}

div.design-system-2 {
  h1,
  button {
    font-weight: normal !important;
  }

  a:not(.navbar) {
    color: #78a9ff;
  }

  .subtext {
    font-size: 0.85em;
    line-height: 1.1em;
  }
}

.markdown-container {
  div {
    color: white;
    background-color: transparent;

    h1,
    h2 {
      border-bottom: none;
    }

    div.w-md-editor-toolbar {
      background-color: #3b3b3b;
      button {
        height: 30px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
    div.w-md-editor-content {
      background-color: black;
    }
  }
}

// fix z-index problem with other inputs
// reference: https://github.com/Hacker0x01/react-datepicker/issues/1252
.react-datepicker-popper {
  z-index: 3 !important;
}

.text-area-code textarea:focus,
.text-area-code textarea:hover {
  outline: none;
  border: 1px solid @purple;
}

.error.text-area-code textarea {
  border: 1px solid red;
}

.text-area-code textarea {
  border-radius: 0.25rem;
  font-family: monospace;
  font-weight: normal; /* or bold, if needed */
  font-size: small;
  background-color: #252525;
  color: white;
}

div.hide-on-small-devices {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

@container point-properties (max-width: 500px) {
  .properties-panel {
    flex-direction: column;

    div.divider.vertical {
      display: none;
    }
    div.divider:not(.vertical) {
      display: block !important;
    }
  }
}

div.limit-height {
  max-height: max(50vh, 200px);
  overflow-y: auto;
}

.custom-marker-cluster {
  background: radial-gradient(
    circle,
    rgba(132, 33, 142, 1) 33%,
    rgba(220, 115, 255, 0.4066001400560224) 80%
  );
  box-shadow:
    0 0 10px rgba(132, 33, 142, 0.5),
    0 0 25px rgba(220, 115, 255, 0.2);
  border-radius: 50%;
  color: white;
  line-height: 45px;
  font-weight: bold;
  text-align: center;
}

//if we are using a labelled input inside a form, we want to add space for validation messages
//and specify a container for the validation message width
form:has(div.labelled-input-container) {
  form {
    .labelled-input-container {
      margin-bottom: 1rem;
      container-type: inline-size;

      .input-validation-content {
        width: "calc(100cqw - 2rem)"; //allow the input validation to take up the width of the overall input parent width, even if input itself is partial (e.g. Classifier set confidence threshold for Cosima AI)
      }
    }
  }
}
