/*******************************
         Site Overrides
*******************************/
i.red.icon:hover {
  color: white !important
}

i.caret.down.icon {
  padding-left: 0.5rem; 
} 

i.exclamation.purpleLight {
  color: @purpleLight !important
}