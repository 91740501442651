@light-purple: #dc73ff;

// table resizer
.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 3px;
  background: @purple;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.ltr {
  right: -2px;
  z-index: 1;
}

.resizer.isResizing {
  background: @light-purple;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
