/*******************************
         Site Overrides
*******************************/

.ui.header {
    h3&,h4& {
        color: @grey;
        margin-top: 1rem;
    }

    h2&,h1& {
        margin-top: 1rem;
    }
}

.ui.header div:not(:first-child){
    margin-top: .5em;    
}