/*******************************
         Site Overrides
*******************************/

.ui.menu .item {
    line-height: 2;
}
.selected-gradient {
      background: rgb(230, 229, 220);
  background: linear-gradient(
    180deg,
    rgba(230, 229, 220, 1) 0%,
    rgba(214, 152, 210, 1) 50%,
    rgba(173, 134, 207, 1) 100%
  );
}

.ui.vertical.menu {    
    .item:before {
        display: none;
    }

    .item.group:after {
        position: absolute;
        content: '';
        bottom: 0%;
        left: 0px;
        right: 0px;
        width: 90%;
        margin: auto;
        height: @dividerSize;
        background: @invertedVerticalDividerBackground;
    }
    .iconStyle{
        font-size:2.6em;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    
    div.item.dropdown { 
        .icon.caret{
            position: absolute;
            right: 0.25em;
            font-size: 1.5em;
            bottom: 50%;
        }

        .menu {
            border-width: 1px;
            border-style: solid;
            border-image: linear-gradient(to bottom, darken(white, 40%), lighten(black, 40%)) 1;
            min-width: 10rem;
            z-index: 2000;



            .item.nested-hover-container:hover {                
                .nested-hover {
                    width: .75rem;
                    opacity: 1;
                }
            }

            .divider {
                border-top: 1px solid white;
                left: 0px;
                right: 0px;
                width: 90%;        
                margin: auto;
            }

            
        .option:before {
                height: 2rem;
                width: 2rem;
                background-color: red;
                position: fixed;
                top: 10px;
            }
        }

        //KNOWN ISSUE - submenu not closing if click on the main menu item then hover over a different menu item also with submenu
        //PROBLEM - when click on main menu, semantic UI adds classes "visible active" to the menu item and "visible" to the submenu underneath, 
        // which means it persists on the screen even after hovering off the main menu item
        //SOLUTION - when main menu item is "visible active" after clicking, but is not hovered, hide the submenu

        &.submenu-container:hover {
            .menu.transition {
                display: block;
            }
        }
        &.submenu-container:not(:hover) {
            .menu.transition.visible {
                display: none !important;
            }
        }
    }    
}

.ui.inverted.menu .active.item {
    background: @activeItemBackground;
}


.ui.vertical.inverted.menu .menu .item,
.ui.vertical.inverted.menu .menu .item a:not(.ui) {
    height: 3rem;
}

.ui.inverted.basic {
    background: @greyDark;
}


/****************************
Pagination is a type of menu
*****************************/

.ui.inverted.menu.pagination {
    background-image: none;

    a.item {
        border: 1px solid darken(@grey, 15%);
        padding: .25rem .75rem;
        min-width: unset;

        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &:hover {
            background-color: @purpleLight;
        }

        &.active {
            background-color: @greyDark;
        }
    }
}